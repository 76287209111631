.checkout-item-container {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;
  .image-container {
    width: 25%;
    padding-right: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .name,
  .quantity,
  .price {
    width: 25%;
  }
  .quantity {
    margin: 0 10px;
    display: flex;
    i {
      cursor: pointer;
      padding-top: 5px;
    }
    .value {
      margin: 0 10px;
    }
  }
  .remove-button {
    padding-left: 12px;
    cursor: pointer;
  }
}

.cart-item-container {
  width: 100%;
  display: flex;
  height: 80px;
  margin-bottom: 10px;
  img {
    width: 30%;
    border-radius: 10px;
  }
  .item-details {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;
    .name {
      font-size: 16px;
    }
  }
}

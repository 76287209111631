.products-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 50px;
}

.category-title {
  margin-top: 30px;
}
